export const segments = [
  { text: "55" },
  { text: "60" },
  { text: "65" },
  { text: "75" },
  { text: "85" },
  { text: "70" },
  { text: "150" },
  { text: "80" },
  { text: "100" },
  { text: "250" },
  { text: "90" },
];
export const questions = [
  { question: "How much blood does your heart pump every minute?" },
  { question: "How many miles of blood vessels are in your body?" },
  {
    question:
      "What does the average heartbeat of a woman compare to that of a man in terms of beats per minute?",
  },
  { question: "Which side of your heart pumps blood into your lungs?" },
  { question: "Which side of your heart pumps blood back through your body?" },
  {
    question:
      "Which day of the week is associated with the highest occurrence of heart attacks?",
  },
  {
    question:
      "Which part of the human body does not receive blood from the heart?",
  },
  { question: "What is the leading cause of death worldwide?" },
  {
    question:
      "What is the size of an adult's heart compared to the size of a child's heart?",
  },
  {
    question: "Which year was it the first year to celebrate World Heart Day?",
  },
  { question: "How many pumps does your heart beat per day?" },
];
