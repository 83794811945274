import "./App.css";
import Winwheel from "winwheel/lib/Winwheel";
import WheelOfFortune from "./components/wheel";
import { useEffect, useState } from "react";

function App() {
  const [showResult, setShowResult] = useState(false);

  return (
    <div className="App">
      {/* logo */}
      {/* <img className="logo_image" src={"logoo.png"} alt="logo" /> */}
      <WheelOfFortune setShowResult={setShowResult} />

      {showResult?.name ? (
        <div className="result-backdrop">
          <h3>{showResult?.name}</h3>
          <h4>{showResult?.question?.question}</h4>
          {/* <div style={{ color: "rgba(180,180,180,1)" }}>Answers:</div> */}
          <div className="answers-container">
            {showResult?.question?.answers?.map((answer, index) => {
              return (
                <h5 key={index}>
                  <div
                    style={{
                      color: "white",
                      display: "inline",
                      marginRight: "18px",
                    }}
                  >
                    <span>{String.fromCharCode(97 + index).toUpperCase()}</span>
                    .
                  </div>

                  {answer}
                </h5>
              );
            })}
          </div>
          <button
            id="restart_button"
            onClick={() => {
              setShowResult(false);
              //refresh
              window.location.reload();
            }}
          ></button>
        </div>
      ) : null}
    </div>
  );
}

export default App;
